import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import WeDash from '../../assets/img/logo.svg';
import { Header } from '../../components';
import { getHelp } from '../../services/faq';
import { headerOptions } from '../Home/mockProps';
import * as S from './styled';

const topicMap = {
  GENERAL_VISION: 'Visão Geral',
  GENERAL: 'Geral',
  GOOGLE_ANALYTICS_DATA: 'Dados do Google Analytics',
  MENUS: 'Menus',
  RD_STATION_DATA: 'Dados do RD Station',
};

const Faq = () => {
  const [faqData, setFaqData] = useState({});
  const [filter, setFilter] = useState('');

  const fetchFaq = useCallback(async () => {
    try {
      const { data: response } = await getHelp();

      const responseMap = new Map();

      response.map(({ topic, ...rest }) => {
        const previousValue = responseMap.get(topic) || [];
        const value = [rest, ...previousValue];

        return responseMap.set(topic, value);
      });

      const result = Object.fromEntries(responseMap);
      setFaqData(result);
    } catch (err) {
      setFaqData(prev => ({ ...prev }));
    }
  }, []);

  useEffect(() => {
    fetchFaq();
  }, [fetchFaq]);

  return (
    <>
      <Header
        logo={WeDash}
        navLinks={headerOptions}
        navButton="https://app.wedash.digital/"
      />

      <S.Container>
        <S.Header>
          <Typography
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: 48,
            }}
          >
            Dúvidas Frequentes
          </Typography>

          <TextField
            variant="outlined"
            label="Pesquisar"
            name="search"
            placeholder="Encontre aqui sua dúvida"
            InputLabelProps={{ shrink: true }}
            style={{ width: '100%', maxWidth: 580, marginTop: 50 }}
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </S.Header>
        <S.Body>
          <S.Category>
            {Object.keys(faqData).map(topic => (
              <>
                <S.CategoryTitle key={Object.keys(topic)[0]}>
                  {topicMap[topic]}
                </S.CategoryTitle>
                {faqData[topic]
                  .filter(help =>
                    help.question.toLowerCase().includes(filter.toLowerCase()),
                  )
                  .map(question => (
                    <S.Question
                      key={question.id}
                      question={question.question}
                      answer={question.response}
                    />
                  ))}
              </>
            ))}
          </S.Category>
        </S.Body>
      </S.Container>
    </>
  );
};

export default Faq;
