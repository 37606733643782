import { PageWrapper } from '../components';
import Faq from '../templates/Faq';

export default PageWrapper(
  Faq,
  'We.Dash | Dashboards De Marketing, Vendas e Growth',
  'https://wedash.digital',
  'Todas Campanhas De Marketing e Vendas Em Única Plataforma Com Dashboards Intuitivos. We.Dash Te Ajuda a Tomar As Melhores Decisões Nos Negócios. Comece Já!',
  'wedash-by-bowe',
);
