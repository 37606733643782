import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1160px) {
    padding: 0 40px;
  }
`;

export const Header = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 85px;

  @media (max-width: 1024px) {
    margin-top: 60px;
  }
`;

export const Body = styled.div`
  margin-top: 80px;
  padding-bottom: 40px;
`;

export const Category = styled.div`
  margin-top: 40px;
`;

export const CategoryTitle = styled(Typography)`
  position: relative;
  padding-left: 17px;
  color: #222137;

  &.MuiTypography-root {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
  }

  ${({ theme }) => css`
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 7px;
      height: 33px;
      background-color: ${theme.colors.primary};
    }
  `}
`;

export const Question = ({ question, answer }) => (
  <Box
    border="1px solid #CBCCCC"
    borderRadius="6px"
    px="20px"
    py="22px"
    mt="24px"
  >
    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#222137' }}>
      {question}
    </Typography>
    <Typography
      dangerouslySetInnerHTML={{ __html: answer }}
      style={{ fontSize: 16, color: '#757575', marginTop: 21 }}
    />
  </Box>
);

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
